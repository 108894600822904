import React from 'react'

const IconArrow = () => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1981 7.96495C13.256 9.23571 11.7344 11.3415 10.6333 14.2098H9.8124C10.1928 12.1766 10.8135 10.2523 11.6944 8.47325C10.0527 9.88924 8.77131 11.8135 7.87036 14.2098H7.00945C7.38985 12.1766 8.0105 10.2523 8.91145 8.47325H0.0020752V6.40373H8.91145C8.03053 4.58836 7.38985 2.66407 7.00945 0.630859H7.85034C8.75129 3.02715 10.0326 4.95144 11.6944 6.40373C10.7934 4.47944 10.1528 2.59146 9.8124 0.630859H10.6533C11.7544 3.53545 13.276 5.60497 15.2181 6.91204V7.96495H15.1981Z"
        fill="white"
      />
    </svg>
  )
}

export default IconArrow
